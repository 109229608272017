.flag{
    width: 24px;
    height: 24px;
    margin-right: 2px;
    }
    
    .showsidebarnav{
        display: none;
    }
    .setsearchbarwidth{
      width: 50%;
    }
    .plus-img {
      position: fixed;
      bottom: 20px;
      right: 4%;
      width: 80px;
      height: 80px;
      cursor: pointer;
    }
    .instructordropzone {
        width: 100%;
        height: 257px;
      background-color: #35920a15;
        border: 1px solid #34920A;
        /* border-radius: 16px; */
        text-align: center;
      padding: 10px;
      /* color: red; */
    }
    
    .instructorupload-icon {
        margin: 200px 2px 2px 2px;
    }
    
    .instructorupload-input {
      cursor: pointer;
        position: relative;
        top: -118px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        /* border: 1px solid red; */
    padding-top: 100px;
    padding-bottom: 100px;
    }
    .instructordropzoneuploadimg {
        width: 100%;
        height: 300px;
        /* border-radius: 16px; */
      
      /* color: red; */
    }
    .instructoruploadimg-input {
      cursor: pointer;
      width: 32px;
      height: 32px;
        opacity: 0;
        position: absolute;
      bottom: 10px;
      right: 1.5%;
    }
    .instructoruploaded-image{
      width: 100%; /* Makes image scale to the container width */
      height: 100%; /* Makes image scale to the container height */
      object-fit: cover; /* Crops the image to fit within the box */
      object-position: center;
      /* width:100%;
    height: 257px; */
    
    /* border-radius: 16px; */
    }
    .instructordropzoneuploadimg-container {
      position: relative;
    }
    
    .bottom-right-image {
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      right: 1.5%;
    }
    
    
      .sidebarbtn {
        
        color: #9E9E9E; /* Change text color for active link */
      }
      .sidebartext{
        color: #333333;
      }
      .sidebarbtncolor {
        /* background-color: #fff; */
        color: #34920A; /* Change text color for active link */
        .sidebartext{
            color: #34920A; 
          }
      }
      .formfieldwidth{
        width: 100%;
      }
      .left-notes {
        background-color: #f5f5f5;
      }
      
      .left-notes:hover {
        background-color: #34920a;
        color: white;
      }
      
      .left-notes .note-icon-hover {
        display: none;
      }
      
      .left-notes:hover .note-icon {
        display: none;
      }
      
      .left-notes:hover .note-icon-hover {
        display: inline;
      }
      .selected-note {
        background-color: #e0f7fa; /* Light blue background for the selected note */
        border-left: 4px solid #00796b; /* A teal left border to indicate selection */
      }
      @media (max-width: 768px) {
          .sidebarhide {
            display: none;
          }
          .showsidebarnav{
             display: block;
          }
          .setsearchbarwidth{
            width: 100%;
          }
          .paddingtoadjustmiduploadlogo{
            margin-top: 15px;
          }
        }

        .scroll-container-invite {
          max-height: 15rem ;
          overflow-y: auto;
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
          scrollbar-width: none;  /* Firefox */
        }
        
        .scroll-container-invite::-webkit-scrollbar {
          display: none;  /* Safari and Chrome */
        }
        
      
        .left-chats {
          display: flex;
          flex-direction: column;
          max-height: 640px;
          overflow-y: scroll;
      }
      .left-create-notes {
        display: flex;
        flex-direction: column;
        height: 640px;
        overflow-y: scroll;
    }
    .left-create-notes::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .left-create-notes {
      scrollbar-width: none;
  }
  @media (max-width: 991px) {
      .left-create-notes {
          
          max-height: 225px; 
          margin-bottom: 5px;
          border-bottom: 1px solid rgb(0, 0, 0);
      }
     
    }
    
  @media (min-width: 991px) {
      .left-create-notes{
          border-right: 1px solid #EEEEEE;
      }
  }
      /* Hide scrollbar for Chrome, Safari, and Edge */
      .left-chats::-webkit-scrollbar {
          display: none;
      }
      
      /* Hide scrollbar for Firefox */
      .left-chats {
          scrollbar-width: none;
      }
      @media (max-width: 991px) {
          .left-chats {
              
              max-height: 225px; 
              margin-bottom: 5px;
              border-bottom: 1px solid rgb(0, 0, 0);
          }
         
        }
        @media (max-width: 991px) {
          .chat-message-left,
          .chat-message-right {
              width: 100%; 
          }
          .notes-view {
              
              max-height: 500px;
              
          }
      }
      @media (min-width: 991px) {
          .left-chats{
              border-right: 1px solid #EEEEEE;
          }
      }
      .notes-view {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        overflow-y: scroll;
    }
    
    /* Hide scrollbar for Chrome, Safari, and Edge */
    .notes-view::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for Firefox */
    .notes-view {
        scrollbar-width: none;
    }
    .form-switch-lg .form-check-input {  
      transform: scale(1.3); /* Increase the scale to make it larger */
    }
    .form-switch-lg .form-check-input:checked {
      background-color: #34920A; /* Optional: Change the color when checked */
    }