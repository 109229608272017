.month-link {
  text-decoration: none;
  color: inherit;
}

.month-link:hover {
  text-decoration: none;
  color: inherit;
}

.selected-month {
  background-color: #34920A;
  color: white;
}

.calendar {
  display: flex;
  flex-direction: column;
}

.calendar-header, .calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-header > div, .calendar-day {
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
}

.calendar-day {
  height: 173px;
  position: relative;
}

.calendar-day:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.day-number {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 14px;
  font-weight: bold;
}

.note {
  position: absolute;
  top: 10%;
  
  padding: 5px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
}

.note-added {
  /* border-radius: 5px; */
  background-color: #29cc3918;
  color: #29CC39;
}
.day-event-font{
  font-size: 20px;
}
.matchdayyearplannercardcolor{
  background-color: #DDDDDD;
  border: 1.77px solid #616569;
}
@media (max-width: 1200px) {
  .calendar-day {
    height: 150px;
  }
  .day-event-font{
    font-size: 16px;
  }
  .note {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .calendar-day {
    height: 130px;
  }
  .day-event-font{
    font-size: 12px;
  }
  .note {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .calendar-day {
    height: 110px;
  }

  .day-number {
    font-size: 12px;
  }

  .note {
    font-size: 10px;
  }
  .day-event-font{
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .calendar-day {
    height: 90px;
  }

  .day-number {
    font-size: 10px;
  }

  .note {
    font-size: 7px;
  }
  .day-event-font{
    font-size: 8px;
  }
}

@media (max-width: 400px) {
  .calendar-day {
    height: 80px;
  }

  .day-number {
    font-size: 8px;
  }

  .note {
    font-size: 7px;
  }
  .day-event-font{
    font-size: 8px;
  }
}
