*{
    font-family: 'Poppins';
    /* color: #1D191C; */
  }
.font-600{
    font-weight: 600;
}
.font-500{
  font-weight:500;
}
.btn-success{
  background-color: #34920A;
}
.bg-success{
  background-color: #34920A !important;
}
.btn-outline-success{
  color: #34920A;
}
.btn-outline-success:hover{
  background-color: #34920A;
}
.text-default{
  color: #34920A;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader div {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}